import { Router } from 'framework7/bundle';
import { SETTINGS, getSetting, isFirstStart } from '../js/settings.ts';

import NotFoundPage from '../pages/404.f7';

export const ROUTE_INTRODUCTION = '/introduction';
export const ROUTE_SETTINGS = '/settings/';
export const ROUTE_SESSION = '/sunrise/';
export const ROUTE_SESSION_5M = `${ROUTE_SESSION}5`;
export const ROUTE_STATS = `${ROUTE_SETTINGS}statistics/`;
export const ROUTE_DEBUG = '/debug';
export const ROUTE_HOME = '/';



var showIntroPage = isFirstStart();

export const setShowIntroPage = (show: boolean) => {
  showIntroPage = show;
}

var routes: Router.RouteParameters[] = [
  {
    path: '/',
    // dynamically resolve the home page based on first start status
    asyncComponent: () => showIntroPage ? import('../pages/introduction.f7') : import('../pages/home.f7'),
    options: {
      animate: false,
      transition: 'f7-fade',
    },
  },
  {
    path: ROUTE_SESSION + ':duration', // duration of session in minutes
    // component: SunrisePage, load async to help with resources
    asyncComponent: () => import('../pages/sunrise.f7'),
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: ROUTE_INTRODUCTION,
    asyncComponent: () => import('../pages/introduction.f7'),
    options: {
      animate: false,
    },
  },
  {
    path: ROUTE_SETTINGS,
    asyncComponent: () => import('../pages/settings.f7'),
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: `${ROUTE_SETTINGS}notifications/`,
    asyncComponent: () => import('../pages/settings/notifications.f7'),
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: `${ROUTE_SETTINGS}session/`,
    asyncComponent: () => import('../pages/settings/session.f7'),
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: `${ROUTE_SETTINGS}licenses/`,
    asyncComponent: () => import('../pages/settings/licenses.f7'),
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: `${ROUTE_SETTINGS}about/`,
    asyncComponent: () => import('../pages/settings/about.f7'),
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: `${ROUTE_SETTINGS}privacy/`,
    asyncComponent: () => import('../pages/settings/privacy.f7'),
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: ROUTE_STATS,
    asyncComponent: () => import('../pages/settings/stats.f7'),
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: ROUTE_DEBUG,
    asyncComponent: () => import('../pages/debug.f7'),
    options: {
      transition: 'f7-parallax',
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export const navigateSession = (f7router: Router, minutes?: number, props?: object) => {
  if (!minutes) { // use settings
    minutes = getSetting(SETTINGS.default_session_time) as number;
  }
  f7router.navigate(`${ROUTE_SESSION}${minutes}`, {
    history: false,
    browserHistory: false,
    ...props
  });
};


export default routes;

