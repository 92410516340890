console.time('appStart')
console.time('appImport')

import Framework7 from 'framework7/bundle';
import { registerSW } from 'virtual:pwa-register';
import { Event, recordEvent } from './analytics.ts';
import { device, initApp } from './app.ts';

// Import F7 Styles
import 'framework7/css/bundle';

// Import Icons and App Custom Styles
import '../css/app.css';
import '../css/icons.css';

// not using console history
import './console-history.js';

// Import Capacitor APIs
import capacitorApp from './capacitor-app.ts';
// Import Routes
import routes from './routes.ts';
// Import Storer
import store from './store.ts';

// Import main app component
import App from '../app.f7';

console.timeEnd('appImport') // time imports
console.time('uiCreate')

export const app = new Framework7({
  name: 'Coherence', // App name
  theme: 'md', // force Material Theme
  darkMode: true,
  el: '#app', // App root element
  component: App,
  store: store,
  routes: routes,
  // Input settings
  input: {
    scrollIntoViewOnFocus: device.native,
    scrollIntoViewCentered: device.native,
  },
  // Capacitor Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: true,
  },
  view: {
    browserHistory: device.web, // only in 
  },
  on: {
    init: function () {
      const f7 = this; // eslint-disable-line
      // install error handler to log errors
      initApp(f7);

      if (!device.native) {  // register service worker now only for web browsers
        registerSW({ immediate: true }); // force reload if new update found
      }
      // init
      console.time('uiInit');
      if (device.native) {
        capacitorApp.init(f7);
      }
      console.timeEnd('uiInit');
    },
    pageTabShow: function (tabEl) { // hack to get all pages views
      const newUrl = tabEl.dataset?.name // use data-name
      newUrl && recordEvent(Event.PageView, {
        page_location: `/${newUrl}`
      })
    },
    routeChange: function (newRoute) { // eslint-disable-line
      const newUrl = newRoute.url || newRoute.path
      newUrl && recordEvent(Event.PageView, { // eslint-disable-line no-undef
        page_location: newUrl,
      })
    },
    connection: function (isOnline) {
      if (isOnline) {
        console.log('app is online now') // TODO record event
      } else {
        console.log('app is offline now')
      }
    }
  }
});

console.timeEnd('uiCreate')
console.timeEnd('appStart')