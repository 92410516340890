import { getStatsSessionNumber } from "./session.ts";
import { StoreValue, deleteStoredValue, getStoredValue, setStoredValue } from "./settings.ts";
import { round } from "./utils.ts";

declare let gtag: Function; // eslint-disable-line


export enum Event {
    /** A page view*/
    PageView = "page_view",
    /** An error / exception*/
    Error = 'exception',
    /** Share the app/link*/
    Share = 'share',
    /** Log this event when a user joins a group */
    JoinGroup = 'join_group',
    /** Log this event when a coherence session starts */
    SessionStart = 'add_to_cart',
    /** Log this event when a coherence session ends */
    SessionEnd = 'purchase',
}

export function recordAnalytic(event: Event | string, data: object) {
    if (typeof gtag !== 'undefined') {
        gtag('event', event, data);
    }
}

export function recordEvent(event: Event | string, data: object) {
    console.debug(`event:${event}: ${JSON.stringify(data)}`);
    recordAnalytic(event, data);
}

const SESSION_START_PURCHASE_EVENT = {
    item_id: "coherence_session",
    item_name: "Coherence Session",
}

/**
 * Update user consent to report anymous analytics
 *
 * @param {boolean} enabled use false to disable analytics reporting
 */
export function updateConsent(enabled: boolean) {
    if (!enabled) { // save disabled in localstorage so index.html/gtag can know
        setStoredValue(StoreValue.analytics, { 'disabled': true });
    } else {
        deleteStoredValue(StoreValue.analytics); // remove value (enabled)
    }
    // update analytics consent 
    const consent = enabled ? 'granted' : 'denied';
    console.debug(`Analytics consent: ${enabled}`);
    gtag('consent', 'update', {
        'ad_personalization': 'denied', // no ads personalization
        'ad_user_data': 'denied',       // no ads user data
        'analytics_storage': consent,
        'ad_storage': consent,
    });
}

/**
 * Are analytics enabled
 *
 */
export function isEnabled() {
    return getStoredValue(StoreValue.analytics) === null; // empty means disabled
}

export function recordEventSessionStart(lengthMinutes: number) {
    const rValue = round(lengthMinutes, 2);
    recordEvent(Event.SessionStart, {
        currency: "USD",
        value: rValue,
        items: [
            {
                ...SESSION_START_PURCHASE_EVENT,
                index: 0,
                price: rValue,
                quantity: 1
            }
        ]
    });
}

export function recordEventSessionEnd(lengthMinutes: number) {
    const rValue = round(lengthMinutes, 2);
    recordEvent(Event.SessionEnd, {
        transaction_id: Math.random().toString(16).slice(2),
        currency: "USD",
        value: rValue,
        item_variant: getStatsSessionNumber().toString(), // store session number
        items: [ // have to record the same item as put in basket
            {
                ...SESSION_START_PURCHASE_EVENT,
                index: 0,
                price: rValue,
                quantity: 1
            }
        ]
    });
}
