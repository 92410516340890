import { App } from '@capacitor/app';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import Framework7 from 'framework7/types';
import { Event, recordAnalytic, recordEvent } from './analytics.ts';
import translate from './i18n.ts';
import { StoreValue, getStoredValue, setStoredValue } from './settings.ts';

type CallbackFunction = () => void;

/**
 * Global application events
 */
export enum EVENT {
    /**
     * will update store setInSession true/false
     */
    sessionStateChange = 'sessionStateChange',
    /**
     * Indicate settings have been updated
     */
    settingsChanged = 'settingsChanged',
    /**
    * Adds a notification in the notification widget
    * @see components/notifications.f7
    */
    notificationAdd = 'notifications:add',
    /**
     * Ask notifications widget to save
     * @see components/notifications.f7
     */
    notificationSave = 'notifications:save',
    /**
     * Open (popup) a MinutePicker component
     * @see components/minutepicker.f7
     */
    minutepickerOpen = 'minutepicker:open',
}


export function logError(message: string, error?: object) {
    if (error) {
        message += `: ${JSON.stringify(error)}`;
    }
    console.error(message, error);
    recordAnalytic(Event.Error, { 'description': message });
}

// TODO handle Promise
export function runAndLogErrors(func: CallbackFunction, message: string = `Error while calling function ${func.name || 'anonymous'}`) {
    try {
        func();
    } catch (err) {
        logError(message, err);
    }
}

interface DeviceInfo {
    platform: string,
    native: boolean,
    ios: boolean,
    android: boolean,
    /* not native, browser/web/PWA */
    web: boolean
}

interface AppInfo {
    version: string,
    build: string,
}

function getDeviceInfo() {
    const nativePlatform = Capacitor.isNativePlatform()
    const platform = Capacitor.getPlatform()
    const deviceInfo: DeviceInfo = {
        platform: platform,
        native: nativePlatform,
        ios: platform === 'ios',
        android: platform === 'android',
        web: platform === 'web',
    }

    return deviceInfo
}

export const device = getDeviceInfo();

export const isDevMode = import.meta.env.DEV;

export async function minimizeApp() {
    if (device.native) await App.minimizeApp(); // only no native devices
}

export const removeHtml = (html: string, replacement: string = '') => {
    return html.replace(/<[^>]*>/g, replacement);
}

export async function shareApp() {
    const canshare = await Share.canShare();
    if (!canshare.value) {
        logError('navigator.share is not available');
        return;
    }

    try {
        const shareRslt = await Share.share({
            title: translate('about.slogan'),
            text: removeHtml(translate('about.description')), // TODO name user
            url: 'https://coherence-app.com',
            dialogTitle: 'Share Coherence',
        });
        // analytics
        recordEvent(Event.Share, { content_type: "app", method: shareRslt.activityType });

    } catch (err) {
        logError('Error sharing', err)
    }
}

var f7app: Framework7 | null = null;

// show errors in the UI
export const showToast = (message: string) => {
    f7app!.toast.create({
        text: message,
        closeTimeout: 3000,
        closeButton: true,
    }).open(); // TODO: add a report issue link
}

/**
 * log the error in console.error(), record an error analytics event 
 * and show in the UI 
 */
export const logAndShowError = (message: string) => {
    logError(message); // console.error and report analytics
    // show in UI
    f7app!.toast.create({
        text: message,
        closeTimeout: 3000,
        closeButton: true,
    }).open(); // TODO: add a report issue link
}

/**
 * Init the app instance
 * 
 * @param f7app 
 */
export function initApp(f7: Framework7) {
    f7app = f7; // save f7 app instance

    // replace default handler
    window.onerror = function (message, url, linenumber) {
        logAndShowError(`Error: ${message}\nLine ${linenumber} for ${url}`);
    }
    // listen to promise rejections
    window.addEventListener("unhandledrejection", (event) => {
        logAndShowError(`Async Error: ${event.reason}`);
    });

    const startCount = getStoredValue(StoreValue.start_count, 0);
    setStoredValue(StoreValue.start_count, startCount + 1); // increment start count
}

export async function getAppInfo() {
    const nativePlatform = Capacitor.isNativePlatform()
    var version = __APP_VERSION__
    var build = 'unavailable'

    // get device app information
    if (nativePlatform) {
        const appInfo = await App.getInfo();
        version = appInfo.version
        build = appInfo.build
    }

    const appInfo: AppInfo = {
        version: version,
        build: build,
    }

    return appInfo
}


export async function browseURL(url: string) {
    recordEvent(Event.PageView, { page_location: url })
    if (device.native && url.startsWith('mailto')) {
        await AppLauncher.openUrl({ url: url }); // on IOS and Android open the mailto app
    } else {
        await Browser.open({ url: url });
    }
}

export function showInstallToast(f7: Framework7) {
    // show only on web browsers on device app can be installed
    if (!device.web) return;
    const userAgent = navigator.userAgent.toLowerCase();

    // Checks if should display install popup notification:
    let installUrl = null;
    if (userAgent.match(/(iphone|ipad)/)) {
        // IOS: Detects if device is in standalone mode
        if (('standalone' in window.navigator) && (window.navigator.standalone))
            return; // app already added to home screen
        installUrl = ''; // TODO IOS URL
    } else if (userAgent.match(/android/)) {
        installUrl = 'https://play.google.com/store/apps/details?id=com.genux.coherence&utm_source=web&utm_content=install_toast';
    }
    if (!installUrl) return; // not on a web broswer where the app can be installed

    const toast = f7.toast.create({
        // icon: `<a href="#"><i class="material-icons">star</i>Install Now</a>`,
        text: `<a class='external link' href="${installUrl}">${translate('home.install_now')}</a>`,
        closeTimeout: 30 * 1000,
        closeButton: true,
    });
    toast.open();
    return toast; // TODO: add a report issue link
}

export async function closeSplashScreen() {
    if (!device.native) {
        return
    }
    const hideSplash = async () => {
        await SplashScreen.hide()
        console.debug("Closed splash screen")
    }

    await StatusBar.hide().then(hideSplash).catch((err) => {
        hideSplash()
        console.error(err)
    });
}
