
import { App } from '@capacitor/app';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import Framework7 from 'framework7/bundle';
import { setupNotifications } from '../js/notifications.ts';
import { EVENT, closeSplashScreen, device, minimizeApp, runAndLogErrors } from './app.ts';
import { startForegroundService, stopForegroundService } from './notifications.ts';
import { isFirstStart } from './settings.ts';
import store from './store.ts';

/**
 * Only imported or run on native code (capacitor app instance)
 */
var capacitorApp = {
  f7: null as Framework7, // eslint-disable-line

  init: function (f7: Framework7) {
    // Save f7 instance
    capacitorApp.f7 = f7;

    // Handle Android back button
    capacitorApp.handleAndroidBackButton();

    // Handle Keyboard
    capacitorApp.handleKeyboard();

    // extra listeners
    capacitorApp.handleAppEvents();

    // schedule notifications (and catch errors)
    if (!isFirstStart()) { // on first start the introduction page will do this 
      runAndLogErrors(() => setupNotifications(f7));
    }
    // close splash now
    closeSplashScreen();
  },

  handleAppEvents: async function () {
    const f7 = capacitorApp.f7;

    f7.router.on(EVENT.sessionStateChange, function (inSession: boolean) {
      console.debug(`${EVENT.sessionStateChange} inSession=${inSession}`);
      // update session state in global store 
      store.dispatch('setInSession', inSession) // TODO is this used?

      if (inSession) {
        startForegroundService(); // notify user a session is in progress, keep phone on
      } else {
        stopForegroundService(); // end notification that a session is in progress
      }
    });

    await App.addListener('appUrlOpen', (data) => {
      console.debug('App opened with URL: ' + data.url);
    });

    await App.addListener('appRestoredResult', (data) => {
      console.debug('Restored state:', data);
    });
  },

  /*
  This method prevents back button tap to exit from app on android.
  In case there is an opened modal it will close that modal instead.
  In case there is a current view with navigation history, it will go back instead.
  */
  handleAndroidBackButton: function () {
    const f7 = capacitorApp.f7;
    const $ = f7.$;
    App.addListener('backButton',
      function () {
        if ($('.session-sunrise').length) { // prevent stopping a running session
          // TODO instead check a current view / page has a 'onBackButton()' function and make it generic
          if ($('.fab').length) {
            f7.fab.toggle('.fab');
          }
          return;
        }
        if ($('.actions-modal.modal-in').length) {
          f7.actions.close('.actions-modal.modal-in');
          return;
        }
        if ($('.dialog.modal-in').length) {
          f7.dialog.close('.dialog.modal-in');
          return;
        }
        if ($('.sheet-modal.modal-in').length) {
          f7.sheet.close('.sheet-modal.modal-in');
          return;
        }
        if ($('.popover.modal-in').length) {
          f7.popover.close('.popover.modal-in');
          return;
        }
        if ($('.popup.modal-in').length) {
          if ($('.popup.modal-in>.view').length) {
            const currentView = f7.views.get('.popup.modal-in>.view');
            if (currentView && currentView.router && currentView.router.history.length > 1) {
              currentView.router.back();
              return;
            }
          }
          f7.popup.close('.popup.modal-in');
          return;
        }
        if ($('.login-screen.modal-in').length) {
          f7.loginScreen.close('.login-screen.modal-in');
          return;
        }
        if ($('.page-current .searchbar-enabled').length) {
          f7.searchbar.disable('.page-current .searchbar-enabled');
          return;
        }
        if ($('.page-current .card-expandable.card-opened').length) {
          f7.card.close('.page-current .card-expandable.card-opened');
          return;
        }

        // go back the page
        const currentView = f7.views.current;
        if (currentView && currentView.router && currentView.router.history.length > 1) {
          currentView.router.back();
          return;
        }

        // leave when hitting back on home screen
        if (currentView && currentView.name === 'home') {
          minimizeApp();
          return;
        }

        if ($('.panel.panel-in').length) {
          f7.panel.close('.panel.panel-in');
          return;
        }
      }
    );
  },
  /*
  This method does the following:
    - provides cross-platform view "shrinking" on keyboard open/close
    - hides keyboard accessory bar for all inputs except where it required
  */
  handleKeyboard: function () {
    const f7 = capacitorApp.f7;
    const $ = f7.$;
    // IOS only
    if (device.ios) {
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
      Keyboard.setScroll({ isDisabled: true });
      Keyboard.setAccessoryBarVisible({ isVisible: false });
    }
    // end IOs only

    window.addEventListener('keyboardWillShow', () => {
      f7.input.scrollIntoView(document.activeElement, 0, true, true);
    });
    window.addEventListener('keyboardDidShow', () => {
      f7.input.scrollIntoView(document.activeElement, 0, true, true);
    });
    window.addEventListener('keyboardDidHide', () => {
      if (document.activeElement && $(document.activeElement).parents('.messagebar').length) {
        return;
      }
      // IOS only
      if (device.ios) {
        Keyboard.setAccessoryBarVisible({ isVisible: true });
      }
    });

    $(document).on(
      'touchstart',
      'input, textarea, select',
      function (e: any) { // eslint-disable-line
        var nodeName = e.target.nodeName.toLowerCase();
        var type = e.target.type;
        var showForTypes = ['datetime-local', 'time', 'date', 'datetime'];

        if (device.ios) {
          if (nodeName === 'select' || showForTypes.indexOf(type) >= 0) {
            Keyboard.setAccessoryBarVisible({ isVisible: true });
          } else {
            Keyboard.setAccessoryBarVisible({ isVisible: false });
          }
        }
      },
      true,
    );
  }
};

export default capacitorApp;
