import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { format as formatDate, formatDistance, formatRelative, isDate } from "date-fns";
import { default as arTr } from "../locales/ar.json";
import { default as deTr } from "../locales/de.json";
import { default as enTr } from "../locales/en.json";
import { default as esTr } from "../locales/es.json";
import { default as frTr } from "../locales/fr.json";
import { default as hiTr } from "../locales/hi.json";
import { default as itTr } from "../locales/it.json";
import { default as jaTr } from "../locales/ja.json";
import { default as koTr } from "../locales/ko.json";
import { default as ruTr } from "../locales/ru.json";
import { default as viTr } from "../locales/vi.json";
import { default as zhTr } from "../locales/zh.json";
import { Event, recordEvent } from './analytics';
import { getUser } from './settings';
import { Locale, setDefaultOptions } from "date-fns";
import { ar, de, enUS, es, fr, hi, it, ja, ko, ru, vi, zhCN } from "date-fns/locale";
import { scheduleNotifications } from './notifications';

const resources = {
  en: { translation: enTr },
  ru: { translation: ruTr },
  es: { translation: esTr },
  fr: { translation: frTr },
  it: { translation: itTr },
  de: { translation: deTr },
  zh: { translation: zhTr },
  ja: { translation: jaTr },
  ko: { translation: koTr },
  vi: { translation: viTr },
  hi: { translation: hiTr },
  ar: { translation: arTr },
}

export const languages = {
  en: 'English',
  ru: 'русский',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  de: 'Deutsch',
  zh: '中文',
  ja: '日本語',
  ko: '한국어',
  vi: 'Tiếng Việt',
  hi: 'हिन्दी',
  ar: 'العربية', // TODO: handle right to left lang
}

const codeToDateLocale: Record<string, Locale> = {
  'en': enUS,
  'ru': ru,
  'es': es,
  'fr': fr,
  'it': it,
  'de': de,
  'zh': zhCN,
  'ja': ja,
  'ko': ko,
  'vi': vi,
  'hi': hi,
  'ar': ar,
}

const LANG_STORAGE_KEY = 'coherence-lang';

// export a simple interface for translation
export const translate = (key: string, options?: any): string => { // eslint-disable-line  @typescript-eslint/no-explicit-any
  let translation = i18next.t(key, options) as string;
  translation = translation.replace(/\s+/g, ' ') // normalize spaces. i.e. for empty variables
  return translation;
}

// export a simple interface for translation
export const translateWithUsername = (key: string, options: object = {}): string => {
  const user = getUser();
  const translation = translate(key, { ...options, ...user }); // add user info
  // remove multiple whitespaces into a single, useful when interpolation var is empty like username
  return translation.replace(/\s+/g, ' ');
}

export const trUnescaped = (key: string, options: object = {}): string => {
  return i18next.getResource(i18next.language, '', key, options);
}

export const getLanguage = (): string => {
  if (!i18next.language) return 'en';
  return i18next.language.substring(0, 2); // first 2 letters
}

export const setLanguage = (lang: string) => {
  window.localStorage.setItem(LANG_STORAGE_KEY, lang);
  i18next.changeLanguage(lang);
  // change date format
  updateDateLang();
  // record lang change as an event
  recordEvent(Event.JoinGroup, { group_id: lang });
  // re-schedule notifications with the new language
  scheduleNotifications(false);
}


export const updateDateLang = () => {
  const langCode = getLanguage();
  let locale = enUS;
  // check that lang exists or use EN
  if (langCode in codeToDateLocale) {
    locale = codeToDateLocale[langCode];
  }
  // change date format globally
  setDefaultOptions({ locale: locale });
}


// TODO lazy load with https://github.com/dotcore64/i18next-fetch-backend ?
i18next.use(LanguageDetector).init({
  // detection: options,
  detection: { order: ['localStorage', 'navigator'], lookupLocalStorage: LANG_STORAGE_KEY },
  resources: resources,
  load: 'languageOnly', //  'en' instead of en-US
  // debug: true,
  supportedLngs: Object.keys(languages),
  fallbackLng: {
    'de-CH': ['fr', 'it'], //French and Italian are also spoken in Switzerland
    'es': ['fr'],
    'default': ['en']
  },
  interpolation: {
    format: (value, format, lng) => {
      if (isDate(value)) {
        if (format === undefined) return;
        let locale = enUS; // check that lang exists or use EN
        if (lng && lng in codeToDateLocale) {
          locale = codeToDateLocale[lng];
        }
        if (format === "short")
          return formatDate(value, "P", { locale });
        if (format === "long")
          return formatDate(value, "PPPP", { locale });
        if (format === "relative")
          return formatRelative(value, new Date(), { locale });
        if (format === "ago")
          return formatDistance(value, new Date(), { locale, addSuffix: true });

        return formatDate(value, format, { locale });
      }

      return value;
    }
  }
}, function () { // after init callback
  // Sets the defaults for all date functions
  updateDateLang();
});

export default translate;